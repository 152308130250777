import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1 className="text-center" style={{ fontSize: `200px` }}>
          <span role="img" aria-label="robot">
            🤖
          </span>
        </h1>
        <p className="text-center">
          You&#39;re trying to discover{' '}
          <span role="img" aria-label="magnifying-glass">
            🔎
          </span>{' '}
          things which do not exist.
          <br />
          You must be an{' '}
          <em>
            ADVENTURER ‍‍
            <span role="img" aria-label="Man climbing emoji">
              🧗
            </span>
          </em>
          .
          <br />
          <i>Keep up the sprit!!</i>{' '}
          <span role="img" aria-label="Horns emoji">
            🤘🏻
          </span>
          <br />
          One day you might find what you are looking for.
          <br /> If you are feeling lost and want to go home{' '}
          <span role="img" aria-label="Home emoji">
            🏡
          </span>
          , follow this <a href="/">route</a>.
        </p>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
